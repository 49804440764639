import './AboutUs.css'

//import { Container } from 'react-bootstrap';

import logohh from './images/logo-hh-desktop.svg'
import logoinsta from './images/logo-instagram.svg'
import logowhats from './images/logo-whatsapp-white.svg'
import logoface from './images/logo-facebook.svg'
import iconocerrar from './images/icono-cerrar.svg'


function AboutUs()
{
    return (
        

        <div className="about d-flex align-items-center">
            <div className='container-fluid p-5 fixed-top'>
                <div className='pos-1 d-flex justify-content-end'>
                            
                    
                    <div className='d-flex'>

                        <div className='px-3 mb-0 align-self-center'>
                            <p className='m-0 body-3 text-white'>En | Sp</p>
                        </div>

                        <div className=''>
                            <a  href="#"><img src={iconocerrar}  alt="menu"/></a>              
                        </div>

                    </div>
                </div>
            </div>                 
        

            <div className="container">
                
                <div className='row'>
                
                    <div className="col-6">
                        <div className='h1 text-white font-bold'>
                            <div><a href='#'>About Us</a></div>
                            <div><a href='#'>How it Works</a></div>
                            <div><a href='#'>Price</a></div>
                        </div>

                        <div className='body-0 text-white my-5' >
                            <a href='/#/login'>Login </a> | <a href='/#/bookdemo'> Book a Demo</a> 
                        </div>

                    </div>

                    <div className="frame2 col-6 ps-5" >

                        <div className='d-flex flex-column justify-content-between h-100'>

                            <div>
                                <h2 className='h2 font-bold text-white'>Let's get in touch</h2>
                                <div className='d-flex gap-5 my-4'>
                                    <a href='#'><img src={logoface} alt="logohh"/></a>
                                    <a href='#'><img src={logoinsta} alt="logohh"/></a>
                                    <a href='#'><img src={logowhats} alt="logohh"/></a>
                                    
                                </div> 
                            </div>

                            
                            <div>
                                <img src={logohh} alt="logohh"/>
                            </div>
                        </div>                       

                                               
                    </div>
                </div>
                <div className='row'>

                    <div className='d-flex body-3 gap-1 pt-5 text-white'>
                        <a href='#'>Hunter&Hire 2023</a> | <a href='#'>Aviso de Privacidad</a> | <a href='#'>Terms and Conditions</a>
                    </div>

                </div>

            </div>   

        </div>

    )

}

export default AboutUs ;