import Button from 'react-bootstrap/Button';
import './ClimbingUp.css'

function ClimbingUp() {

    return (
        <div className="climb">
            <div className='container'>
                <div className="row">
                    <div className='d-flex flex-column text-center'>
                        <h2 className="h2 font-bold text-white">Climbing Up</h2>                    
                        <h3 className="h2 font-bold text-gradient-bp ">Hunter & Hire Ranks</h3>
                    </div>                   
                </div>

                <div className='row justify-content-center'>
                <div className="col-4">
                    </div>
                    <div className="col-2">
                        <h4 className="font-bold body-0 text-center text-secondary">I'm a</h4>
                    </div>
                    <div className="col-2">
                        <h4 className="font-bold body-0 text-center text-secondary">We are a</h4>
                    </div>
                    <div className="col-4">
                    </div>
                </div>

                <div className='row align-self-end gx-0'>
                    <div className="col-3">
                    </div>
                    <div className="col-3 ">
                        <Button className='buttonInfo-1 font-bold '>Employeer</Button>
                    </div>
                    <div className="col-3">
                        <Button className='buttonInfo-2 font-bold'>Executive Search Firms or HHS</Button>
                    </div>
                    <div className="col-3">
                    </div>
                </div>


                <div className='row d-flex justify-content-between mt-5'>
                    <div className='col-6 flex-column text-center'>
                        <div>
                            <h5 className='body-0 font-bold text-white text-center my-5'>Hiring Pro</h5>
                        </div>
                        <div className='div-1 my-2'>
                            <p className='font-bold text-white body-0 m-0'>3 Months</p>
                            <p className='text-white body-3 m-0'>Additional warranty per position</p>
                        </div>
                        <div className='div-1 my-2'>
                            <p className='font-bold text-white body-0 m-0'>3 Months</p>
                            <p className='text-white body-3 m-0'>Additional warranty per position</p>
                        </div>
                        <div className='div-3 my-2'>
                            <p className='font-bold text-white body-0 m-0'>Preferential Fee</p>
                        </div>
                        <div className='div-3 my-2'>
                            <p className='font-bold text-white body-0 m-0'>Preferential Fee</p>
                        </div>                        
                    </div>

                    <div className='col-6 flex-column text-center'>
                        <div>
                            <h5 className='body-0 font-bold text-white text-center my-5'>Stafing Elite</h5>
                        </div>
                        <div className='div-2 my-2'>
                            <p className='font-bold text-white body-0 m-0'>5 Months</p>
                            <p className='text-white body-3 m-0'>Additional warranty per position</p>
                        </div>
                        <div className='div-2 my-2'>
                            <p className='font-bold text-white body-0 m-0'>5 Months</p>
                            <p className='text-white body-3 m-0'>Additional warranty per position</p>
                        </div>
                        <div className='div-4 my-2'>
                            <p className='font-bold text-white body-0 m-0'>Preferential Fee</p>
                        </div>
                        <div className='div-4 my-2'>
                        <p className='font-bold text-white body-0 m-0'>Preferential Fee</p>
                        </div>
                    </div>
                </div>  
            </div>


        </div>
    )
}

export default ClimbingUp ;