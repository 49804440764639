import React , {useState , useEffect , useRef } from "react";

import Address2 from "./Address2";

import IndustriesAreas from "./IndustriesAreas";


function Company(props) {


    const [address2,setAddress2] = useState( {}) ;


    const companyNameElement = useRef();
    const comercialNameElement = useRef();
    const descriptionElement = useRef();
    const companyEmailElement = useRef();
    const websiteElement = useRef() ;


    useEffect(() =>{

        onChange() ;


    } ,[address2])


    function onChange()
    {

        var obj = {
            companyName : companyNameElement.current.value ,
            comercialName : comercialNameElement.current.value ,
            website : websiteElement.current.value ,
            description : descriptionElement.current.value ,
            companyEmail : companyEmailElement.current.value ,
            address2 : address2 
        }

        props.setCompany( obj ) ;
    }
 



    return (
    
        <div>
                <div className="d-flex gap-3">
                    <div className="w-100">
                        <label htmlFor="companyname">Company Name</label>
                        <input className="form-control" placeholder="Company Name"  onChange={onChange}
                        ref={companyNameElement}
                        id="companyname" type="text" maxLength={80} />
                    </div>
                    <div className="w-100" >
                        <label htmlFor="comercialname">Comercial Name</label>
                        <input className="form-control" placeholder="Comercial Name" onChange={onChange}
                        ref={comercialNameElement}
                        id="comercialname" type="text" maxLength={80} />
                    </div>
                </div>                    


                <div className="d-flex gap-3">
                    <div className="w-100">
                        <label htmlFor="description">Description</label>
                        <input className="form-control" placeholder="Description" onChange={onChange}
                        ref={descriptionElement}
                        id="description" type="text" maxLength={80} size= {80}/>
                    </div>
                    <div className="w-100">
                        <label htmlFor="companyemail">Company email</label>
                        <input className="form-control" placeholder="Company email" onChange={onChange}
                        ref={companyEmailElement} 
                        id="companyemail" type="text" maxLength={80} size= {80}/>
                    </div>
                </div>
                
                <div className="d-flex gap-3">
                    <div className="w-100">
                        <label htmlFor="website">WebSite</label>
                        <input className="form-control" placeholder="WebSite" onChange={onChange}
                        ref={websiteElement}
                        id="website" type="text" maxLength={200} size= {80}/>
                    </div>                   
                </div>
                <div>
                <Address2 setAddress2={setAddress2}/>
                </div>


            
            {/* {
                props.isIndustry ==="true" ? ( <div><label>Industries</label> <IndustriesAreas/> </div>): ""
            }
            */}


        </div>
    )

}

export default Company ;