import React , {useState , useEffect } from 'react'

import { Navigate, useNavigate } from 'react-router-dom'

import Email from './Email'
import Password from './Password'
import FirstlastName from './FirstLastName'

import Phone  from './Phone'
import Address from './Address'
import Company from './Company'
import ErrorMessage from './ErrorMessage'


import { Row , Col } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {   faXmarkCircle } from '@fortawesome/free-solid-svg-icons'
import { faCircleUser} from '@fortawesome/free-solid-svg-icons'
import LinkEdin from './LinkEdin'

import axios from 'axios'
import { Globals } from '../../Api/Globals'
import { Globals2 } from '../../Api/Globals2'

import logohh from '../../images/logo-hh-login.svg'





export default function UserAdd( {closeTable , email , password ,independent , isHunter}) {

    console.log( "UserAdd = " + independent ) ;

    const navigate = useNavigate();

    const [isErr,setIsErr] = useState( false ) ;
    const [firstLastName , setFirstLastName ] = useState( { firstName :'' , lastName :''})
    const [phone,setPhone] = useState( { phone :'' , secondaryPhone :''})
    const [linkedin,setLinkEdin] = useState( { code_linkedin :'' , id_linkedin :''})
    const [address,setAddress] = useState( { address :''  , complement : '' , country :0 , state :0 , city :0 ,zipcode :'' })
    const [company,setCompany] = useState({}) ;
    const [errorMessage,setErrorMessage] = useState( false ) ;

    useEffect(() =>{

        console.log( "firstLastName = " + JSON.stringify( firstLastName ) ) ;

        console.log( "phone = " + JSON.stringify( phone ) ) ;

        console.log( "linkedin = " + JSON.stringify( linkedin ) ) ;

        console.log( "address " + JSON.stringify( address )  )

        console.log( "company " + JSON.stringify( company )  )



    } , [firstLastName , phone , linkedin , address , company])

    
    function valideateErr()
    {
        setIsErr( true ) ;
    }

    async function onSubmitHandler(event){

        event.preventDefault() ;

        console.log( "onSubmitHandler") ;

        var value = {
            user : { email : email , password : password } ,
            firstLastName : firstLastName ,
            phone : phone ,
            linkedin : linkedin ,
            address : address ,
            //company : company  ,

            isHunter : isHunter , // Se trata de reclutador
        

        }

        if ( independent || !isHunter  )
        {
            value.company = company ;
        }

        console.log( JSON.stringify( value  ) ) ;


        var url = "" ;

        if ( process.env.REACT_APP_production === "false") // production
        {
          console.log( "Entrando local")
          url = Globals.urlBack 
        }
        else{
          console.log( "Entrando remoto")
          url = Globals2.urlBack 
        }

        var retVal = await axios.post(  url + "newUser" , JSON.stringify( value  )  , {
            headers: {
              // Overwrite Axios's automatically set Content-Type
              'Access-Control-Allow-Origin': '*',
              'Content-Type': 'application/json'
            }
          })


          console.log( "Despues de llamar a post")


        console.log( JSON.stringify( retVal ) ) ;

        if ( retVal.data.status === 'ok')
        {
            setErrorMessage( "User successfully added") ;

            return ;


        }

        setErrorMessage( "Error adding user") ;

    }


    function setClose()
    {
      //closeTable() ;
      navigate(-1)
    }

    //container  justify-content-center backemployer-2
  return (
<div className='d-flex flex-column align-items-center'>
    <div className='my-5'>
        <a href='#'><img ms-auto src={logohh} alt="logohh"/></a>
    </div>
    <div className="d-flex flex-column justify-content-center box-1 backemployer-2 mb-5"> 

        <div>            
            <div className='d-flex gap-3 align-items-center tx-color-black font-bold box-2'>
                <FontAwesomeIcon icon={faCircleUser} style={{color:'#A5A5A5' , height:20}}/>
                <h1 className='h4 m-0'>Add User</h1>
            </div>
            {/* <div className=''>
                <FontAwesomeIcon className='pt-2 ps-2' onClick={setClose} icon={faXmarkCircle} 
                title='Close' style={{color:'blue' , height:25 }}/>
            </div> */}

        </div>

        

        <form className="my-5 form-2" onSubmit={onSubmitHandler}>
            <div>
                <div className="d-flex justify-content-between gap-3">
                    <div className='w-100'>
                        <Email email={email} onError={valideateErr}/>
                    </div>
                    <div className='w-100'>
                        <Password password={password} maxLength={50} size={50}  />
                    </div>                    
                </div>
                <div>
                    <FirstlastName maxLength={60} size={60} setFirstLastName={setFirstLastName} />
                </div>
                <div>
                    <div className='w-100'>
                    <Phone maxLength={18} size={18} setPhone={setPhone}/>
                    </div>
                    <div className='w-100'>
                    <LinkEdin setLinkEdin={setLinkEdin}></LinkEdin>
                    </div>
                </div>
                <div>
                    <Address setAddress={setAddress} />
                </div>
                <div>
                    { independent && (
                        <>
                            <hr/>
                            <h2 className='h5 font-bold tx-color-black my-4'>Company</h2>
                            <Company isIndustry="true" setCompany={setCompany}/> 
                        </>

                    )

                    }
                    

                    {/* <button className="btn btn-primary mt-3 mb-3">Submit</button>*/}
                    { isErr && <ErrorMessage title={"este es el titulo"} message={"Este es el mensaje"} /> }
                </div>

            </div> 


            <div class="row">


                <div class="col-2">

                    <div className='pt-4'>
                        <button type="submit"  className="button-3">Register</button>
                    </div>


                </div>

                <div class="col-9"></div>

                <div class="col-1">
                    <div className='pt-4'>
                        <button onClick={() => navigate(-1)}  className="button-3">Back</button>
                    </div>

                </div>


            </div>




            {errorMessage && <div className="body-3 text-center tx-color-pink"> {errorMessage} </div>}

        </form>
    </div>
</div>
  )
}
