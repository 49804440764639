
import { Container } from 'react-bootstrap';
import './Partners.css'

function Partners()
{
    return (
        <div>
            <div className='partners'>
                <div className='container-fluid'>
                    <div className='row'>
                    <div className='col-1'></div>
                        <div className='col-4'>
                            <h2 className='font-bold h2 text-gradient-bg py-5'>
                                Our Partners
                            </h2>
                            <p className='body-1 text-white'>
                                We are proud to have some of the leading companies in their industry as partners. Together, we create synergies thet allow us to increase effectiveness and efficient in the talent search.

                            </p>
                        </div>
                        <div className='col-7 d-flex align-items-center justify-content-end'>   
                            <div>
                                <div className='logo-part m-2'></div>
                                <div className='logo-part m-2'></div>
                            </div>                                         
                            
                            <div>
                                <div className='logo-part m-2'></div>
                                <div className='logo-part m-2'></div>
                                <div className='logo-part m-2'></div>
                            </div>

                            <div>
                                <div className='logo-part m-2'></div>
                                <div className='logo-part m-2'></div>
                                <div className='logo-part m-2'></div>
                                <div className='logo-part m-2'></div>
                            </div>
                            
                        </div>
                    </div>

                </div>

            </div>

        </div>
    )
}

export default Partners ;