import React , { useEffect , useState}from 'react'


import { Cookies  } from 'react-cookie';

import UserAdd from './NewMemberFull/UserAdd';
import { useSearchParams } from 'react-router-dom';

export default function NewMemberAdd( props ) {

    const [ email , setEmail ] = useState( "") ;
    const [ password , setPassword ] = useState( "") ;
    const [ wCompany , setwCompany ] = useState( false) ;
    const [ isHunter , setIsHunter ] = useState( true) ;


    const [searchParams, setSearchParams]  = useSearchParams()


    //console.log( " newmembeadd = " + searchParams.get("email") ) ;

    useEffect(()=>{

        //var email = window.location.state.parms.email ;

        const cookies = new Cookies();


        var myObj = cookies.get( "newmember" )

        console.log( "newMember = " + myObj.password) ;


        setEmail( myObj.email ) ;
        setPassword( myObj.password) ;
        setwCompany( myObj.wCompany ) ;
        setIsHunter( myObj.isHunter  ) ;


        console.log( "Saliendo de useEffect") ;
        


    },[])


  return (

    <div className="d-flex justify-content-center align-items-center">
    <div className=''>

        <div className='d-flex flex-column align-items-center' >



                    <UserAdd email={email} password={password} independent={wCompany} isHunter={isHunter}></UserAdd>




        </div>

      </div>

    </div>

  )
}

