

function ErrorMessage( props ) {

    return (

        <div className="errclass">

            <header>

                <h2>
                    {props.title}
                </h2>
                

            </header>

            <div>

                <p>
                    {props.message}
                </p>

            </div>

            <footer>

                <button>Exit</button>

            </footer>

        </div>

    )

}

export default ErrorMessage ;