import { useState , useEffect , useRef} from 'react';
import { useNavigate , createSearchParams} from 'react-router-dom'
import '../../node_modules/bootstrap/dist/css/bootstrap.min.css';
import './NewMember.css'

import { LoginApi } from "../Api"


import { Cookies  } from 'react-cookie';

//import NewMemberAdd from './NewMemberAdd';

import UserAdd from './NewMemberFull/UserAdd';
//import { Row , Col } from 'react-bootstrap';


import logohh from '../images/logo-hh-login.svg'

const LoginApp = new LoginApi() ;



function NewMember( { isAdd = false })
{
    const navigate = useNavigate();
    const [step2,setStep2] = useState(1) ;
    const [email,setEmail] = useState("") ;
    const [password,setPassword] = useState("") ;
    const [isHunter,setIsHunter] = useState( true ) ;
    const [wCompany,setWCompany] = useState( false ) ;
    const hunterElement = useRef() ;
    const corpoElement = useRef() ;
    const hunter1Element = useRef() ;
    const hunter2Element = useRef() ;

    const [errorMessage,setErrorMessage] = useState("") ;

    function handleSubmit(event) {
        event.preventDefault();


        // var email = document.getElementById("email").value ;
        // var password = document.getElementById("password").value ;

        // console.log( "email = " + email ) ;
        // console.log( "password = " + password ) ;


        // setEmail(email) ;
        // setPassword( password) ;


        // setStep2( 2 ) ;

        // return ;
 
        const mydata = {
            email : document.getElementById( "email").value ,
            password : document.getElementById("password").value ,

        }

        console.log( mydata ) ;

        (async() => {
            await LoginApp.getLogin( mydata , callBack)

            console.log( "Ya termino el setDemo")

            //console.log(  response  ) ;
        })()

    }

    function callBack( result )
    {
        // Do something
        console.log( "Llegando result " + result + " " + typeof( result ) ) ;

        if ( result >= 0)
        {
            console.log( "El usuario no esta registrado, todo bien") ; // Aqui debemos de pasar a cargar datos
            //setErrorMessage( "Error en el Login") ;
            // var data = {

            //     email : document.getElementById( "email").value ,
            //     password : document.getElementById( "password").value 

            // }

            // navigate( "/company" , {state : data}  ) ;


            var email = document.getElementById("email").value ;
            var password = document.getElementById("password").value ;
    
            console.log( "email = " + email ) ;
            console.log( "password = " + password ) ;
    
    
            setEmail(email) ;
            setPassword( password) ;
    
    
            //setStep2( 2 ) ;
            //navigate( "/addmember") ;


            const cookies = new Cookies();

            cookies.set('newmember', 
            { "email" : email , "password" : password , "wCompany" : wCompany , "isHunter" : isHunter });


            navigate( "/newmemberadd" )
    
            return ;
    



        }else{
            setErrorMessage( "previously registered email ...") ;
        }
    }

    // Antes tenia new 

    useEffect(() =>{

        hunterElement.current.checked = true ;
        hunter1Element.current.checked = true ;

    } ,[])


    useEffect(() =>{

        //hunterElement.current.checked = true ;
        if( isHunter)
        {
            hunter1Element.current.checked = true ;
        }

        

    } ,[isHunter])

    useEffect(() =>{

        console.log( "wCompany = " + wCompany ) ;        

    } ,[wCompany])


    function setHunter()
    {
        console.log( "hunter = " + hunterElement.current.checked )

        setIsHunter( hunterElement.current.checked) ;

        //hunterElement.current.checked = true ;

    }

    function setCorpo()
    {
        console.log( "corpo = " + hunterElement.current.checked )

        setIsHunter( false ) ;

        //corpoElement.current.checked = true  ;

    }

    function setIndependentYes()
    {
        setWCompany( true )

        console.log( "Yes " + wCompany ) ;
    }

    function setIndependentNo()
    {
        setWCompany( false  )

        console.log( "No " + wCompany ) ;
    }

    

    return (
        <div className="new d-flex flex-column justify-content-center align-items-center">
            <div className='my-5'>
                <a href='#'><img ms-auto src={logohh} alt="logohh"/></a>
            </div>
            <div className='box-login'>

                <div className='d-flex flex-column align-items-center'>
                    <div className='tx-color-purple body-1'>
                        <h4>New Member Registration</h4>
                    </div>

                    <div className='d-flex justify-content-center w-100'>

                        { !isAdd && ( <div>
                        <form onSubmit={handleSubmit} className='w-100'>
                                <div className="d-flex flex-column align-items-center form-1 display-label-none mb-4">
                                    <label htmlFor="exampleInputEmail1">Email address</label>
                                    <input type="email" id="email" aria-describedby="emailHelp" placeholder="Enter email" />
                                    <small id="emailHelp" className="body-4 tx-color-black mt-2">We'll never share your email with anyone else.</small>
                                </div>
                                <div className="form-1 display-label-none">
                                    <label htmlFor="exampleInputPassword1">Password</label>
                                    <input type="password" className="form-control" id="password" placeholder="Password" />
                                </div>

                                <div className='d-flex justify-content-center my-2 gap-3'>

                                    <div className="d-flex align-items-center gap-2" >
                                        <input className="form-check-input"  name='tipo' 
                                        ref={hunterElement} onClick={setHunter}
                                        type="radio" id="inlineCheckbox1" value="option1"/>
                                        
                                        <label className="form-check-label tx-color-purple body-3" htmlForf="inlineCheckbox1">Hunter</label>
                                    </div>
                                    <div className="d-flex align-items-center gap-2">
                                        <input className="form-check-input" name='tipo' 
                                        ref={corpoElement} onClick={setCorpo}
                                        type="radio" id="inlineCheckbox2" value="option2"/>
                                        
                                        <label className="form-check-label tx-color-purple body-3" htmlFor="inlineCheckbox2">Corporate</label>
                                    </div>
                                </div>

                                { isHunter && (
                                <>
                                <div className='mb-4 d-flex flex-column justify-content-center'>
                                        <div className="form-check form-check-inline" >
                                            <input className="form-check-input"  name='hunter' ref={hunter1Element}
                                            
                                            onClick={setIndependentNo}

                                            type="radio" id="wcompanyyes" value="option1"/>
                                    
                                            <label className="form-check-label tx-color-purple body-3" htmlForf="wcompanyyes">Independent recruiter</label>
                                        </div>
                                        <div className="form-check form-check-inline">
                                            <input className="form-check-input" name='hunter' ref={hunter2Element}
                                            onClick={setIndependentYes}
                                            type="radio" id="wcompanyno" value="option2"/>
                                            
                                            <label className="form-check-label tx-color-purple body-3" htmlFor="companyno">Recruiter with company</label>
                                        </div>
                                </div>

                                </>
                                )

                                }

                                



                                <button type="submit" className="button-2">Submit</button>

 

                                {errorMessage && <div className="frame3 text-center"> {errorMessage} </div>}
                        </form>
                                <button onClick={()=>navigate(-1)} className="button-2 mt-2">Back</button>

                        </div>


                        )

                        }

                        { isAdd && (

                            <UserAdd email={email} password={password} independent={wCompany} isHunter={isHunter}></UserAdd>

                        )

                        }


                    </div>
                </div>

            </div>

        </div>
    )

}

export default NewMember ;