

function Password( props) {

    return (
        <div>

            <label htmlFor="password">Password</label>

            <input className="form-control" readOnly value={props.password} placeholder="Place Password" id="password" type="password" maxLength={props.maxLength} size= {props.size}>
            </input>

        </div>

    )


}

export default Password ;