import './Footer.css'

import logohh from './images/logo-hunterandhire-white.svg'
import logoinsta from './images/logo-instagram.svg'
import logowhats from './images/logo-whatsapp-white.svg'
import logoface from './images/logo-facebook.svg'

function Footer()
{
    return (
        <div className='footer d-flex align-items-center'>

                <div className='container'>
                    <div className='row'>

                        <div className='col-4 text-align-start'>
                            <img src={logohh} alt="logohh"/>
                        </div>

                        <div className='col-8 d-flex flex-column'>

                            <div className='d-flex justify-content-end gap-4'>
                                <a href='#'><img src={logoface} alt="logohh"/></a>
                                <a href='#'><img src={logoinsta} alt="logohh"/></a>
                            </div>

                            <div className='d-flex justify-content-end body-3 gap-1 pt-3 text-white'>

                            <a href='#'>Hunter&Hire 2023</a> | <a href='#'>Aviso de Privacidad</a> | <a href='#'>Terms and Conditions</a>
                            </div>

                        </div>

                    </div>                      

                </div>
        </div>
    )
}

export default Footer ;