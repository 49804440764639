
// Hola
export const Globals = {

    //urlBack : 'http://192.168.1.4:3002/hunthireapi' // Servidor local ( Falta el de produccion )

    //urlBack : 'https://evbrocks.com/hunthireapi' // Para produccion


        //urlBack : 'https://backhh.hunterhire.io/hhback/' // Para produccion ( Peticiones a la nube )
    
        urlBack : 'http://127.0.0.1:3005/hhback/' // Para desarrollo local



    

    //


}