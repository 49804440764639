import {  Route, Routes } from "react-router-dom";


import AppView from "./AppView";
import BookDemo from "./BookDemo";
import AboutUs from './AboutUs'
import Login from './Admin/Login'
import NewMember from "./Admin/NewMember";
import NewMemberAdd from "./Admin/NewMemberAdd";
import Company from "./Admin/Company";

import './styles.css';
import "./fonts/Satoshi-Bold.ttf";
import "./fonts/Satoshi-BoldItalic.ttf";
import "./fonts/Satoshi-Italic.ttf";
import "./fonts/Satoshi-Medium.ttf";
import "./fonts/Satoshi-MediumItalic.ttf";
import "./fonts/Satoshi-Regular.ttf";




function App(){

    return (
        <Routes>
            <Route exact path="/" element={<AppView/>} />
            <Route exact path="/bookdemo" element={<BookDemo/>} />
            <Route exact path="/about" element={<AboutUs/>} />
            <Route exact path="/login" element={<Login/>} />
            <Route exact path="/newmember" element={<NewMember isAdd={false}/>} />
            <Route exact path="/newmemberadd" element={<NewMemberAdd />} />
            <Route exact path="/addmember" element={<NewMember isAdd={true}/>} />
            <Route exact path="/company" element={<Company/> } />


        </Routes> 

    )
}

export default App ;