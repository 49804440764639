import './Howit.css'

import { useRef , useEffect} from 'react';

import Lottie from "lottie-react";
import howitworks from './images/how-it-works-animatedBack.json'

function Howit () {

    var lottie = useRef(null) ;

    useEffect(() => {
        lottie.current.setSpeed(1) ;        
    }) ;

    return (

        <div className="Howit">
            <div className="container p-5">
                <h2 className='font-bold text-gradient-bp h2 text-center p-5'>How it works:</h2>

                <div className='howit-lottie'>
                    <Lottie  lottieRef={lottie} animationData={howitworks} loop={false} />;
                </div>
            </div>
        </div>
    ) 
}

export default Howit ;