import '../node_modules/bootstrap/dist/css/bootstrap.min.css';

import './Second.css'

import ficha1 from './images/Ficha1.png' ;
import ficha2 from './images/Ficha2.png' ;


function Second()
{
    return(
        <div>

            <div className='second'>

                <div className='container justify-content-center'>
                    <div className='row py-5'>
                        <div className='col-7'>

                            <p className='body-0 text-white'>
                                HunterHire.io is the number one <span className='font-bold'>user-frendly marketplafe platform user-frendlyuser-frendly</span> created to have a true <span className='font-bold'>win-win</span> between companies and experienced firms & top headhunters <span className='font-bold'>around the world</span>
                            </p>
                        </div>

                        <div className='col-5'>
                            <div className='ficha-1'>
                                <img src={ficha1} alt="ficha1"/>
                            </div>
                            <div className='ficha-2'>
                                <img src={ficha2} alt="ficha2" />
                            </div>
                        </div>
                    </div> 

                    <div className='row py-5'>

                        <div className='hunter-2 col-6'>
                            <h2 className='h2 font-bold text-gradient-bg'>Employers</h2>
                        </div>

                        <div className='col-6 text-white body-1'>

                            <p>We support connecting and managing the best headhunter partners with two import paramaters: real experience of the sector by region and by position, as well as increasing hiring effectiveness in less time.</p>

                        </div>

                     </div>                    

                

                    <hr style={{color:'white'}}></hr>

                    <div className='row py-5'>

                        <div className='hunter-2 col-6 '>
                            <h2 className='h2 font-bold text-gradient-bg'>Executive Search <br></br>firms or HHS</h2>
                        </div>
 
                        <div className='col-6 text-white body-1'>

                            <p>We support connecting and managing the best headhunter partners with two import paramaters: real experience of the sector by region and by position, as well as increasing hiring effectiveness in less time.</p>

                        </div>


                    </div>
                </div>
                

            </div>
            

        </div>
    )
}

export default Second ;