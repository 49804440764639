// Aqui tenemos el telefono principal y el secundario
import React , {useRef} from "react";

function Phone(props) {

    const phoneElement = useRef();
    const secondaryElement = useRef();


    function onChange()
    {

        var obj = {
            phone : phoneElement.current.value ,
            secondaryElement : secondaryElement.current.value 

        }

        props.setPhone( obj ) ;

    }

    return (

    <>
    <div className="d-flex gap-3">

            <div className="w-100" >
                <label htmlFor="phone">Phone</label>
                <input className="form-select"  placeholder="Phone" onChange={onChange} ref={phoneElement}
                    id="phone" type="text" maxLength={props.maxLength} size= {props.size}/>
            </div>

            <div className="w-100" >
                <label htmlFor="secondaryphone">Secondary Phome</label>
                <input className="form-select" ref={secondaryElement} onChange={onChange} 
                    placeholder="Secondary Form" id="secondaryphone" type="text" maxLength={props.maxLength} size= {props.size}/>
            </div>
    </div>


    </>

    )

}

export default Phone ;