import './Testimonials.css'

import star from './images/star-rating.svg' ;

function Testimonials()
{
    return (
        <div>

            <div className="testimonials container-fluid">
                <div className='row'>
                    <div>
                        <h2 className='font-bold text-gradient-bp h2 text-center p-5'>
                            Testimonials
                        </h2>
                    </div>
      
                </div>

                <div className='row'> 
                    <div className='d-flex text-white'>
                        <div className='bloque'>                    
                            <p className='body-2'>
                            Morbi non augue porta, efficitur purus nec, sodales ante. Ut ipsum lectus, lobortis eget ante et, feugiat eleifend dolor. Cras a posuere lorem, et lacinia tellus.

                            </p>
                            <p className='body-4'>
                                COUNTRY
                            </p>
                            <p className='font-bold body-2'>
                                TESTIMONIAL 1
                            </p>
                            
                            <div>
                                <img src={star} alt="star rating" />
                                <img src={star} alt="star rating" />
                                <img src={star} alt="star rating" />
                                <img src={star} alt="star rating" />
                                <img src={star} alt="star rating" />
                            </div>
                        </div>
                        
                        <div className=' bloque'>
                            <p className='body-2'>
                            Morbi non augue porta, efficitur purus nec, sodales ante. Ut ipsum lectus, lobortis eget ante et, feugiat eleifend dolor. Cras a posuere lorem, et lacinia tellus.

                            </p>
                            <p className='body-4'>
                                COUNTRY
                            </p>
                            <p className='font-bold body-2'>
                                TESTIMONIAL 2
                            </p>
                            
                            <div>
                                <img src={star} alt="star rating" />
                                <img src={star} alt="star rating" />
                                <img src={star} alt="star rating" />
                                <img src={star} alt="star rating" />
                                <img src={star} alt="star rating" />
                            </div>
                        </div>
                        <div className='bloque'>
                            <p className='body-2'>
                            Morbi non augue porta, efficitur purus nec, sodales ante. Ut ipsum lectus, lobortis eget ante et, feugiat eleifend dolor. Cras a posuere lorem, et lacinia tellus.

                            </p>
                            <p className='body-4'>
                                COUNTRY
                            </p>
                            <p className='font-bold body-2'>
                                TESTIMONIAL 3
                            </p>
                            
                            <div>
                                <img src={star} alt="star rating" />
                                <img src={star} alt="star rating" />
                                <img src={star} alt="star rating" />
                                <img src={star} alt="star rating" />
                                <img src={star} alt="star rating" />
                            </div>
                        </div>
                        <div className='bloque'>
                            <p className='body-2'>
                            Morbi non augue porta, efficitur purus nec, sodales ante. Ut ipsum lectus, lobortis eget ante et, feugiat eleifend dolor. Cras a posuere lorem, et lacinia tellus.

                            </p>
                            <p className='body-4'>
                                COUNTRY
                            </p>
                            <p className='font-bold body-2'>
                                TESTIMONIAL 4
                            </p>
                            
                            <div>
                                <img src={star} alt="star rating" />
                                <img src={star} alt="star rating" />
                                <img src={star} alt="star rating" />
                                <img src={star} alt="star rating" />
                                <img src={star} alt="star rating" />
                            </div>
                        </div>
                    </div>  
                </div>


            </div>

        </div>
    )
}


export default Testimonials ;