
import { useEffect  , useState , useRef } from "react";

import axios from "axios";

//import getGlobal from "../setglobal";

import { Globals } from '../../Api/Globals';
import { Globals2 } from '../../Api/Globals2';

function citiesCb( response )
{
   var mySelect2 = document.getElementById('city') ;

   buildOptions( response , 'city2' , 'id' , 'name') ;

}

function getCities( id )
{
   console.log( "Llamando a cities = " + id ) ;



   //axios.get("http://localhost:3000/api/Data/getcities?id=" + id )


   var url = "" ;

   if ( process.env.production === "false") // production
   {
     console.log( "Entrando local")
     url = Globals.urlBack 
   }
   else{
     console.log( "Entrando remoto")
     url = Globals2.urlBack 
   }


   axios.get( url + "getcities?id=" + id )
   .then(response => {
     //console.log( JSON.stringify(response ) ) ;

     console.log( "cities") ;

     citiesCb( response.data ) ;

   })
   .catch(e => {
     console.log( "countries") ;
     console.log( e ) ;
   })
   .finally(  () => {


 })
}



function statesCb(response)
 {
     var mySelect2 = document.getElementById('state') ;
     buildOptions( response , 'state2' , 'id' , 'name') ;
 }




function getStates(id)
  {
    //axios.get("http://localhost:3000/api/Data/getstates?id=" + id )

    var url = "" ;

    if ( process.env.production === "false") // production
    {
      console.log( "Entrando local")
      url = Globals.urlBack 
    }
    else{
      console.log( "Entrando remoto")
      url = Globals2.urlBack 
    }


    axios.get( url + "getstates?id=" + id )
    .then(response => {
      //console.log( JSON.stringify(response ) ) ;

      console.log( "getStates " ) ;
      statesCb( response.data ) ;

    })
    .catch(e => {
      console.log( "states error " + e ) ;
      //console.log( e ) ;
    })
    .finally(  () => {


  })

}


function countriesCb(response){
    buildOptions( response , 'country2' , 'id' , 'name') ;
    //getCategories() ;
    getStates() ;
  }
  
  
  function getCountries()
  {
  

    var url = "" ;

    if ( process.env.production === "false") // production
    {
      console.log( "Entrando local")
      url = Globals.urlBack 
    }
    else{
      console.log( "Entrando remoto")
      url = Globals2.urlBack 
    }

    console.log( "Globals = " + url )
  
    //axios.get('http://localhost:3000/api/Data/getcountries')
    axios.get( url + 'getcountries')
    .then(response => {
      console.log( "countries Jalando " + response.data.length ) ;
      countriesCb( response.data ) ;
    })
    .catch(e => {
      console.log( "countries error jalando " + e ) ;
    })
    .finally(  () => {
  
  
  })
  
  }

  function buildOptions( response , element , value , name)
  {



    var mySelect = document.getElementById(element) ;

    if ( mySelect.options === undefined)
    {
        console.log( "undefines") ;
    }
    else{
        console.log( "No es undefined") ;
    }

    if ( mySelect.options.length > 0 )
    {
      removeOptions( mySelect ) ;      
    }


    var option = document.createElement("option");
    option.value = "0";
    option.text = "Select option" ;
    mySelect.appendChild(option);

    

    for (var i = 0; i < response.length; i++) {
      var option = document.createElement("option");
      // option.value = response[i].value_id.toString();
      // option.text = response[i].name ;

      option.value = response[i][value].toString();
      option.text = response[i][name] ;
    
      mySelect.appendChild(option);
    }

  }


  function removeOptions(selectElement) {

    if ( selectElement.options.length < 1 )
    {
      return ; 
    }
  
    var i, L = selectElement.options.length - 1;
    for(i = L; i >= 0; i--) {
       selectElement.remove(i);
    }
  }


function Address2 ( {setAddress2}) {

    const [stateHidden,setStateHidden] = useState(true) ;
    const [cityHidden,setCityHidden] = useState(true) ;

    const addressElement = useRef() ;
    const complementElement = useRef() ;
    const countryElement = useRef() ;
    const stateElement = useRef() ;
    const cityElement = useRef() ;
    const zipCodeElement = useRef() ;


    function onChange()
    {
      var obj={
        address : addressElement.current.value ,
        complement : complementElement.current.value ,
        country : countryElement.current.value ,
        state : stateElement.current.value ,
        city : cityElement.current.value ,
        zipcode : zipCodeElement.current.value 
      }

      setAddress2( obj ) ;

    }


    useEffect(()=>{

        getCountries() ;
    
        
    
      } , [] ) ;


      const handleState = (e) => {


        onChange() ;

        console.log( "handleState = " + e.target.value ) ; 
    
        if ( e.target.value > 0 )
        {
    
      
    
          setCityHidden ( false );
    
          getCities( e.target.value ) ;
        }
        else{
          //setStateHidden ( true );
          setCityHidden( true ) ;
    
          removeOptions( 'city')
    
        }
    
    
      }
      


    const handleCountry = ( e ) => {

        console.log( "handleCountry = " + e.target.value ) ; 

        onChange() ;
    
        if ( e.target.value > 0 )
        {
          setStateHidden ( false );
    
          getStates( e.target.value ) ;
        }
        else{
          setStateHidden ( true );
    
    
        }
    
          setCityHidden( true ) ;
    
          var myCity = document.getElementById( 'city2') ;
    
          removeOptions( myCity)
    
      
    }
    
    

    return (

        <div>
          <div className="d-flex gap-3">
              <div className="w-100">
                  <label htmlFor="address2">Address</label>
                  <input className="form-control" id="address2" onChange={onChange}
                        ref={addressElement} 
                        type="text" maxLength={120} size= {100}/>
              </div>
              <div className="w-100">
                  <label htmlFor="complement2">Complement</label>
                  <input className="form-control" id="complement2" onChange={onChange} 
                        ref={complementElement} 
                        type="text" maxLength={120} size= {100}>
                  </input>
              </div>
          </div>

          <div className="d-flex gap-3">
            <div className="w-100">
                <label htmlFor="country2">Country***</label>
                <select className="form-select" id="country2"  ref={countryElement}
                         onChange={handleCountry}></select>
            </div>                  
            <div className="w-100">
                <label htmlFor="state2">State***</label>
                <select className="form-select" id="state2"  ref={stateElement}
                            onChange={handleState}></select>                    
            </div>
          </div>
          <div className="d-flex gap-3">
              <div className="w-100">
                <label htmlFor="city2">City***</label>
                <select className="form-select" id="city2" ref={cityElement} onChange={onChange}>
                </select> 
              </div>
              <div className="w-100" >
                <label htmlFor="zipcode2">Zip Code</label>
                <input className="form-control" id="zipcode2" onChange={onChange} 
                        ref={zipCodeElement}
                        type="text" maxLength={12}/>
              </div>
          </div>  

        </div>    

    )

}

export default Address2 ;