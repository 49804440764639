import React , {useState , useEffect , useRef } from "react";

import axios from "axios";


//import getGlobal from "../setglobal";

import { Globals } from '../../Api/Globals';
import { Globals2 } from '../../Api/Globals2';

function citiesCb( response )
{
   

   buildOptions( response , 'city' , 'id' , 'name') ;

}

function getCities( id )
{
   console.log( "Llamando a cities = " + id ) ;


   var url = "" ;

   if ( process.env.production === "false") // production
   {
     console.log( "Entrando local")
     url = Globals.urlBack 
   }
   else{
     console.log( "Entrando remoto")
     url = Globals2.urlBack 
   }

   //axios.get("http://localhost:3000/api/Data/getcities?id=" + id )
   axios.get( url + "getcities?id=" + id )
   .then(response => {
     //console.log( JSON.stringify(response ) ) ;

     console.log( "cities") ;

     citiesCb( response.data ) ;

   })
   .catch(e => {
     console.log( "countries") ;
     console.log( e ) ;
   })
   .finally(  () => {


 })
}



function statesCb(response)
 {
     //var mySelect2 = document.getElementById('state') ;
     buildOptions( response , 'state' , 'id' , 'name') ;
 }




function getStates(id)
  {
    //axios.get("http://localhost:3000/api/Data/getstates?id=" + id )

    var url = "" ;

    if ( process.env.production === "false") // production
    {
      console.log( "Entrando local")
      url = Globals.urlBack 
    }
    else{
      console.log( "Entrando remoto")
      url = Globals2.urlBack 
    }


    axios.get( url + "getstates?id=" + id )
    .then(response => {
      //console.log( JSON.stringify(response ) ) ;

      console.log( "getStates " ) ;
      statesCb( response.data ) ;

    })
    .catch(e => {
      console.log( "states error " + e ) ;
      //console.log( e ) ;
    })
    .finally(  () => {


  })

}


function countriesCb(response){
    buildOptions( response , 'country' , 'id' , 'name') ;
    
    getStates() ;
  }
  
  
  function getCountries()
  {
  

    var url = "" ;

    if ( process.env.production === "false") // production
    {
      console.log( "Entrando local")
      url = Globals.urlBack 
    }
    else{
      console.log( "Entrando remoto")
      url = Globals2.urlBack 
    }

    console.log( "Globals = " + url )
  
    //axios.get('http://localhost:3000/api/Data/getcountries')
    axios.get( url + 'getcountries')
    .then(response => {
      console.log( "countries Jalando " + response.data.length ) ;
      countriesCb( response.data ) ;
    })
    .catch(e => {
      console.log( "countries error jalando " + e ) ;
    })
    .finally(  () => {
  
  
  })
  
  }

  function buildOptions( response , element , value , name)
  {


    console.log( "Element = " + element ) ;

    var mySelect = document.getElementById(element) ;

    if ( mySelect.options === undefined)
    {
        console.log( "undefines") ;
    }
    else{
        console.log( "No es undefined") ;
    }

    if ( mySelect.options.length > 0 )
    {
      removeOptions( mySelect ) ;      
    }


    var option = document.createElement("option");
    option.value = "0";
    option.text = "Select option" ;
    mySelect.appendChild(option);

    

    for (var i = 0; i < response.length; i++) {
      var option = document.createElement("option");
      // option.value = response[i].value_id.toString();
      // option.text = response[i].name ;

      option.value = response[i][value].toString();
      option.text = response[i][name] ;
    
      mySelect.appendChild(option);
    }

  }


  function removeOptions(selectElement) {

    if ( selectElement.options.length < 1 )
    {
      return ; 
    }
  
    var i, L = selectElement.options.length - 1;
    for(i = L; i >= 0; i--) {
       selectElement.remove(i);
    }
  }






function Address ( { setAddress}) {

    const [stateHidden,setStateHidden] = useState(true) ;
    const [cityHidden,setCityHidden] = useState(true) ;


    const adressElement = useRef();
    const complementElement = useRef();
    const countryElement = useRef();
    const stateElement = useRef();
    const cityElement = useRef();
    const zipCodeElement = useRef();




    const handleState = (e) => {

        console.log( "handleState = " + e.target.value ) ; 
    
        if ( e.target.value > 0 )
        {
    
      
    
          setCityHidden ( false );
    
          getCities( e.target.value ) ;
        }
        else{
          //setStateHidden ( true );
          setCityHidden( true ) ;
    
          removeOptions( 'city')
    
        }
    
    
      }



    const handleCountry = ( e ) => {

        console.log( "handleCountry = " + e.target.value ) ; 


        onChanged() ; 
    
        if ( e.target.value > 0 )
        {
          setStateHidden ( false );
    
          getStates( e.target.value ) ;
        }
        else{
          setStateHidden ( true );
    
    
        }
    
          setCityHidden( true ) ;
    
          var myCity = document.getElementById( 'city') ;
    
          removeOptions( myCity)
    
      
    }




    useEffect(()=>{

        getCountries() ;
    
      } , [] ) ;


      function onChanged()
      {
          // setFirstLastName
          setAddress( fillName() ) ;
  
      }
  
  
      function fillName()
      {
  
  
          var obj = {
  
              address : adressElement.current.value ,
              complement : complementElement.current.value ,
              country : countryElement.current.value ,
              state : stateElement.current.value ,
              city : cityElement.current.value ,
              zip_code : zipCodeElement.current.value 
  
          }
  
          return obj ;
      }
    

    return (

        <div>
            <div>

                <div className="d-flex gap-3">
                    <div className="w-100" >
                        <label htmlFor="address">Address</label>
                        <input  className="form-control" placeholder="Address" ref={adressElement} onChange={onChanged}
                        id="address" type="text" maxLength={120} size= {100}/>
                    </div>
                    <div className="w-100">
                        <label htmlFor="complement">Complement</label>
                        <input className="form-control" placeholder="Complement" ref={complementElement} onChange={onChanged}
                        id="complement" type="text" maxLength={120} size= {100}/>
                    </div>
                </div>
            </div>

            <div>
                <div className='d-flex gap-3'>
                    <div className="w-100">
                        <label htmlFor="country">Country</label>
                        <select className="form-select" id="country" ref={countryElement} onChange={handleCountry}>
                        </select>

                        {/* <input className="form-control " placeholder="Country" id="country" type="text" /> */}
                    </div>
                    <div className="w-100">
                        <label htmlFor="state">State</label>
                        <select className="form-select" id="state" ref={stateElement} onChange={handleState}>
                        </select>                    



                        {/* <input className="form-control" placeholder="State" id="state" type="text"/> */}
                    </div>
                </div>                    
 
                    

            </div>

                <div className="d-flex gap-3">
                  <div className="w-100">
                        <label htmlFor="city">City</label>
                        <select className="form-control" placeholder="City" id="city" ref={cityElement} onChange={onChanged}>
                        </select>                    

                        {/* <input className="form-control" placeholder="City" id="city" type="text"  /> */}
                    </div>
                    <div className="w-100">
                        <label htmlFor="zipcode">Zip Code</label>
                        <input className="form-control" placeholder="Zip Code" ref={zipCodeElement} onChange={onChanged}
                          id="zipcode" type="text" maxLength={12}/>
                    </div>

                </div>


        </div>

    )

}

export default Address ;