
import { Globals } from "./Globals"; 
import { Globals2 } from "./Globals2"; 


export class Demo{

    //endpoint = 'localhost:3002/bookademo' ;


      async setDemo( jsonData , cb ){

        console.log( "json data = " +  JSON.stringify( jsonData ) ) ;

        var url = "" ;

        if ( process.env.production === "false") // production
        {
          console.log( "Entrando local")
          url = Globals.urlBack + "login"
        }
        else{
          console.log( "Entrando remoto")
          url = Globals2.urlBack + "login"
        }

          var url = url + "bookademo"
  
          //await fetch('http://192.168.1.7:3002/bookademo', {  // Enter your IP address here
          await fetch(url, {  // Enter your IP address here
        
  
              method: 'POST', 
              //mode: 'cors', 
              headers: {
                "content-type": "application/json",  
                //"Accept": "application/json"
              },
              body:  JSON.stringify( jsonData  ) // body data type must match "Content-Type" header
    
        }).then(res => res.json()) 
        .then( (response ) =>{
  
          console.log( "Ya tenemos la respuesta " +  JSON.stringify( response ) ) ;

          cb(response ) ;
  
          return  JSON.stringify(  ) ;
  
        }).catch((err)=>{
  
          console.log( err) ;
  
        } )
        // .finally(() =>{
        //     console.log( "Ya terminamos") ;

        //     return ;
        // }) ;
  
      }
  

}