//import logo from './logo.svg';

import logohh from './images/logo-hh-desktop.svg'

import whats from './images/logo-whatsapp.svg'

import iconomenu from './images/icono-menu.svg'

import ojoportada from './images/ojo-portada.png'

import '../node_modules/bootstrap/dist/css/bootstrap.min.css';

import './AppView.css';
//import { Button } from 'bootstrap';
import Button from 'react-bootstrap/Button';

import Second from './Second';
import Howit from './Howit';
import Optimizing from './Optimizing';
import Partners  from './Partners';
import Testimonials from './Testimonials';
import ClimbingUp from './ClimbingUp';
import Footer from './Footer';
// obtener acceso al ojo y detectar el mouse
import { useRef } from "react";
import { useEffect, useState } from 'react';
//import { gsap } from "gsap";

function AppView() {
  const img__item = useRef();
  
  // Map number x from range [a, b] to [c, d]
  const map = (x, a, b, c, d) => (x - a) * (d - c) / (b - a) + c;
  // Linear interpolation
  const lerp = (a, b, n) => (1 - n) * a + n * b;
  
  const getRandomNumber = (min, max) => Math.floor(Math.random() * (max - min + 1) + min);
   
  const windowSize = useRef([window.innerWidth, window.innerHeight]);
  
  const translationVals = {tx: 10, ty: 10};
  
  const useMousePosition = () => {
      const [mousePosition, setMousePosition] = useState({x: 0, y: 0});
      
      useEffect(() => {
          
          const updateMousePosition = (event) => {
              //setMousePosition({ x: event.clientX, y: event.clientY });
              const xstart = getRandomNumber(15,60);
              const ystart = getRandomNumber(15,60);
              translationVals.tx = lerp(translationVals.tx, map(event.clientX, 0, windowSize.current[0], -xstart, xstart), 0.07);
              translationVals.ty = lerp(translationVals.ty, map(event.clientY, 0, windowSize.current[1], -ystart, ystart), 0.07);
              setMousePosition({ x: translationVals.tx, y: translationVals.ty });
          };
          
          window.addEventListener('mousemove', updateMousePosition);
          
          return () => {
              window.removeEventListener('mousemove', updateMousePosition);
          };
          
      }, []);
      
      return mousePosition
  }

const {x, y} = useMousePosition();
  return (

    <div>

    

    <div className="App-back" >

        <nav className='container-fluid p-5 fixed-top'>
          <div className='pos-1 d-flex justify-content-between'>
            
            <div className=''>
              <img ms-auto src={logohh} alt="logohh"/>
            </div>

            <div className='d-flex'>

              <div className='px-3 mb-0 align-self-center'>
                <p className='m-0 body-3 text-white'>En | Sp</p>
              </div>

              <div className=''>
                <a  href="/#/about"><img src={iconomenu}  alt="menu"/></a>              
              </div>

            </div>

          </div>

        </nav>    

      <header className='content z-1'>
        <div className="App-header">

          <h1 className='font-medium h1'>
            The most effective LATAM<span className='text-gradient-bp mt-1 font-bold'> <br></br>headhunting marketplace</span><br></br>
            platform
          </h1>

          <div className='buttonalign pt-4'>
            <Button className='button-1' href='/#/bookdemo' >Book demo now! </Button>{' '}
          </div>    
          
        </div>
        <div className='m-4 fixed-bottom'>
                <img className='float-end' src={whats} alt="whats"/>
          </div>
    
      </header>
      <div className="grid__item-img position-absolute z-0 d-flex justify-content-center">
        <img ref={img__item} src={ojoportada} alt="ojoportada" style={{ transform: `translate(${x}px, ${y}px)` }}/>
      </div>
      

    </div>
      <Second />
      <Howit />
      <Optimizing/>
      <Partners/>
      <Testimonials/>
      <ClimbingUp/>
      <Footer/>
      
    </div>
  );
}

export default AppView;
