
import { useState } from 'react'



import './Login.css'

import { LoginApi } from "../Api"

import logohh from '../images/logo-hh-login.svg'
//import { Navigate , navigate } from 'react-router-dom';

import { Navigate, useNavigate } from 'react-router-dom'

const LoginApp = new LoginApi() ;

function Login()
{
    const [errorMessage,setErrorMessage] = useState("") ;
    const [isReenter,setIsReenter] = useState( false ) ; // Se reingreso el password 

    const navigate = useNavigate();

    function handleSubmit(event) {
        event.preventDefault();

        var theForm = document.getElementById('myForm') ;
    
        const data = new FormData(theForm);
    
        const value = Object.fromEntries(data.entries());

        //JSON.stringify(Object.fromEntries(data))
    
        console.log(JSON.stringify(value) );

        if ( isReenter )
        {

            var pass = document.getElementById("password").value ;
            var rePass = document.getElementById("repassword").value ;

            if ( pass !== rePass )
            {
                setErrorMessage( "Password doesn't match") ;
                return ;
            }
        }


 
        const mydata = {
            email : document.getElementById( "email").value ,
            password : document.getElementById("password").value ,
            isReenter : isReenter

        }

        console.log( mydata ) ;

        (async() => {
            await LoginApp.getLogin( mydata , callBack)

            console.log( "Ya termino el setDemo")

            //console.log(  response  ) ;
        })()
        

    }

    function callBack( result )
    {
        // Do something
        console.log( "Llegando result " + result + " " + typeof( result ) ) ;

        if ( result===0) // Hay que cambiar el deta
        {
            console.log( "pintamos el error") ;
            setErrorMessage( "User doesn't exist!!!") ;

            return ;
        }

        if ( result === -1 )
        {
            setIsReenter( true ) ;
            console.log( "No coincide el password") ;
            setErrorMessage( "Please Re Enter your password") ;

            return ;

        }

        if ( result === -2 )
        {

            setErrorMessage( "Password Incorrect") ;

            return ;

        }
        
        // else{
        //     setErrorMessage( "Usuario , registrado con exito") ;
        // }
    }


    return (
        <div className="login d-flex flex-column justify-content-center align-items-center">
            <div className='my-5'>
                <a href='#'><img ms-auto src={logohh} alt="logohh"/></a>
            </div>

            <div className='box-login'>

                <div className='d-flex flex-column align-items-center'>

                    <div>
                        <p className='tx-color-purple body-1'>Login</p>
                    </div>

                    <div className='display-label-none form-1 w-100'>                    

                        <form id='myForm' onSubmit={handleSubmit}>
                    
                            <div className="form-outline mb-4">
                                <label className="form-label" htmlFor="form2Example1" >Email address</label>
                                <input type="email" id="email" className="form-control" placeholder="Email"/>
                                
                            </div>

                            
                            <div className="form-outline mb-4">
                                <label className="form-label" htmlFor="form2Example2">Password</label>
                                <input type="password" id="password" className="form-control" placeholder="Password"/>
                                
                            </div>                   
                    

                        
                            <button type="button" onClick={handleSubmit} className="button-2 mb-4">Sign in</button>

                            <button type="button" onClick={()=>navigate(-1)} className="button-2 mb-4">Back</button>


                            {errorMessage && <div className="frame3 text-center"> {errorMessage} </div>}

                            { isReenter && (
                                                <div className="form-outline mb-4">
                                                <input type="password" id="repassword" className="form-control" />
                                                <label className="form-label" htmlFor="form2Example2">Re enter Password</label>
                                            </div>
                            )

                            }

                            <div className="d-flex mb-4 gap-2">
                                {/* <div className="">                        
                                    <div className="">
                                        <input className="" type="checkbox" value="" id="form2Example31"  />
                                        <label className="" htmlFor="form2Example31"> Remember me </label>
                                    </div>
                                </div> */}

                                <div>
                                    <a className='tx-color-purple body-3' href="#!">Forgot password?</a>
                                </div>
                            </div>

                            <div className="d-flex body-3 gap-1">
                                <p>Not a member? <a href="/#/newmember" className='tx-color-gray-dark'>Register</a></p>
                                <p>or sign up with:</p>
                                <button type="button" className="btn btn-link btn-floating mx-1">
                                <i className="fab fa-facebook-f"></i>
                                </button>

                                <button type="button" className="btn btn-link btn-floating mx-1">
                                <i className="fab fa-google"></i>
                                </button>

                                <button type="button" className="btn btn-link btn-floating mx-1">
                                <i className="fab fa-twitter"></i>
                                </button>

                                <button type="button" className="btn btn-link btn-floating mx-1">
                                <i className="fab fa-github"></i>
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>


        </div>
    )



}

export default Login ;