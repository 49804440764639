


function Email( {email})
{
    const onBlurHandler = (event) => { // verificar la existencia del correo electronico

        event.preventDefault() ;

        console.log( "validar email") ;

        //props.onError() ;



    }
    return (
        <div className="">

            <label htmlFor="email">Email</label>

            <input className="form-control" readOnly value={email} id="email" type="email"  placeholder="Email">
            </input>

        </div>
    )

}

export default Email ;