
import { Globals } from "./Globals";
import { Globals2 } from "./Globals2";



import axios from "axios";

export class LoginApi{

    //endpoint = 'localhost:3002/bookademo' ;


      async getLogin( jsonData , cb ){

        console.log( "json data = " +  JSON.stringify( jsonData ) ) ;
          var url = "" ;

          console.log( "production = " + JSON.stringify( process.env.REACT_APP_production ) )

          if ( process.env.REACT_APP_backend === "false") // production
          {
            console.log( "Entrando local")
            url = Globals.urlBack + "login"
          }
          else{
            console.log( "Entrando remoto")
            url = Globals2.urlBack + "login"
          }
          

          console.log( url ) ;

          axios.post( url , JSON.stringify( jsonData  )  , {
            headers: {
              // Overwrite Axios's automatically set Content-Type
              'Access-Control-Allow-Origin': '*',
              'Content-Type': 'application/json'
            }
          })
        .then( (response) => {

            console.log( "regresando ") ;

            console.log( "********HHHH*********" +  JSON.stringify( response.data.id ) + " isPassword = " + response.data.isPassword ) 


            if ( response.data.id  > 0 && response.data.isPassword === false && response.data.isHH === 1)
            {
              cb(-2) ;
              return ;

            }

            if ( response.data.id  > 0 && response.data.isPassword === false )
            {
              cb(-1) ;
              return ;
            }


            if ( response.data.id === 0 )
            {
              cb( 0 ) ;

              return ;

            }

            console.log( "isHH = " + response.data.isHH ) ;
            console.log( "sessionId = " + response.data.id )

            if ( process.env.REACT_APP_production === "false") 
            {
              //console.log( "Vamos local")
              window.location.replace('http://127.0.0.1:3010/?session=' + response.data.id );
            }
            else{
              //console.log( "Vamos remoto") ;
              window.location.replace('https://hunterdash.hunterhire.io/?session=' + response.data.id );
            }
            //window.location.replace('http://127.0.0.1:3010/?session=' + response.data.id );
            //window.location.replace('https://hunterdash.hunterhire.io/?session=' + response.data.id );

        }).catch( (e) => {
            console.log( e ) ;
            //resolve( "error") ;
        })
 
      }
  

}