import './Optimizing.css'

import grafica1 from './images/platform-icons.png'
import '../node_modules/bootstrap/dist/css/bootstrap.min.css';
import Button from 'react-bootstrap/Button';



function Optimizing () {
    return (

     
        <div className="optim">
            <div className='container'>
                <div className='row'>
                        <div className='col-6'>
                            <h2 className='font-bold h2 text-gradient-bp py-5' >
                                Optimizing Search Process
                            </h2>
                        
                            <p className='body-1 text-white'>
                                Disruptive data analisys engine, Hunter&Hire Smart Machine AI Tecnology and experienced consultants, will work 24/7 for employers and the headhunter ecosystem mazimizing its chances of success.
                                <br></br><br></br>
                                At HunterHire.io up to three most suitable and efficient Suppliers will be assigned per job oppotunity with candidates ready to be delivered. H&H will match its users most relevant experience and historical introduced candidates with new and future job opportunities to automate and optimize hiring desicion-marking process. When a job opportunity is posted, whithin a few days, solid potential candidates from qualified recruiters with specific expertise in a location, industry or function will be delivered.
                                <br></br><br></br>
                                H&H platform ensures effective vendor management and communication bettween all parties throughout theprocess.
                            </p>

                        </div>
                        <div className='col-6 d-flex flex-column align-items-center justify-content-around '>
                            <div className=''>
                                <img className='' src={grafica1} alt="ficha1"/>
                            </div>
                            <div className='buttonalign'>
                            <Button className='button-1' href='/#/bookdemo' >Talk to an Advisor </Button>{' '}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    ) ;
}

export default Optimizing ;