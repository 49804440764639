import React , {useRef} from "react";

function LinkEdin(props)
{
    const codeElement = useRef();
    const idElement = useRef();

    function onChanged()
    {
        // setFirstLastName
        props.setLinkEdin( fillName() ) ;

    }


    function fillName()
    {

        //console.log( "fillName = " + firstElement.current.value + " " + lastElement.current.value ) ;

        var obj = {

            code_linkedin : codeElement.current.value ,
            id_linkedin : idElement.current.value 

        }

        return obj ;
    }

    return (
        <div className="d-flex gap-3">

                <div className="w-100" >
                    <label htmlFor="firstname">LinkEdin Code</label>
                    <input className="form-control" onChange={onChanged} ref={codeElement} 
                        placeholder="LinkEdinCode" id="linkcode" type="text" maxLength={props.maxLength} size= {props.size}>
                    </input>

                </div>

                <div className="w-100" >
                    <label htmlFor="lastname">LinkEdin ID</label>
                    <input className="form-control" placeholder="LinkEdin ID"  onChange={onChanged} 
                        ref={idElement}
                        id="linkid" type="text" maxLength={props.maxLength} size= {props.size}>
                    </input>
                </div>

        </div>
    )

}

export default LinkEdin ;