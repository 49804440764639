import React , {useRef} from "react";

function FirstlastName(props)
{
    const firstElement = useRef();
    const lastElement = useRef();

    function onChanged()
    {
        // setFirstLastName
        props.setFirstLastName( fillName() ) ;

    }


    function fillName()
    {

        //console.log( "fillName = " + firstElement.current.value + " " + lastElement.current.value ) ;

        var obj = {

            firstName : firstElement.current.value ,
            lastName : lastElement.current.value 

        }

        return obj ;
    }

    return (
        <div className="d-flex gap-3">
                <div className="w-100">
                    <label htmlFor="firstname">First Name</label>
                    <input className="form-control" onChange={onChanged} ref={firstElement} 
                        placeholder="Firt Name" id="firstname" type="text" maxLength={props.maxLength} size= {props.size}>
                    </input>

                </div>


            <div className="w-100">
                <label htmlFor="lastname">Last Name</label>
                 <input className="form-control" placeholder="Last Name"  onChange={onChanged} 
                        ref={lastElement}
                        id="lastname" type="text" maxLength={props.maxLength} size= {props.size}>
                </input>
            </div>
        </div>
    )

}

export default FirstlastName ;